<template>
  <div class="addBox">
    <!-- 内容区 -->
    <div class="add">
      <div class="addleft">
        <div class="leftbox">
          <div
            class="onetxt"
            @click="goStep(1)"
            :class="step===1?'active':''"
          >1</div>
          <div
            class="base"
            @click="goStep(1)"
            :class="step===1?'active1':''"
          >首页轮播图</div>
          <div class="line"></div>
          <div
            class="onetxt"
            @click="goStep(2)"
            :class="step===2?'active':''"
          >2</div>
          <div
            class="base"
            @click="goStep(2)"
            :class="step===2?'active1':''"
          >销售类目</div>
        </div>
      </div>
      <div class="addright">

        <div class="phoneboard mgl-54">

          <div class="tip">
            <a-popover
              overlayClassName="poptips2"
              trigger="hover"
              placement="bottomRight"
            >
              <template slot="content">
                <div class="toopt2">
                  建议用深色的头部背景，过渡到浅色的<br />
                  背景色以适配整体的展示。
                </div>
                <div class="toopt3">头部的重要信息以放置在安全区域设计最佳。</div>
                <div class="toopt4">750px</div>
                <div class="toopt5">380px</div>
                <img
                  style="width:168px;border: 1px solid #979797;"
                  src="@/assets/images/qykepop.jpg"
                  alt=""
                  class="pop1"
                />
                <img
                  src="@/assets/images/pop2.png"
                  alt=""
                  class=" pop2"
                />
                <img
                  src="@/assets/images/pop3.png"
                  alt=""
                  class="pop3"
                />
                <img
                  src="@/assets/images/egarrow.png"
                  alt=""
                  class="pop5"
                />
              </template>
              <span class="tip_sp">示例</span>
            </a-popover>
          </div>
          <!-- 图片展示内容 -->
          <div class="picture_board">
            <img
              class="mainbg"
              src="@/assets/images/mainbg.jpg"
              alt=""
            >
            <div class="picture_board_swicher">
              <div class="swiper">
                <Swiper
                  :options="swiperOption"
                  ref="mySwiper"
                >
                  <swiper-slide>
                    <img
                      class="sliderimg"
                      src="@/assets/images/bloc.png"
                      alt=""
                    >
                  </swiper-slide>
                  <swiper-slide>
                    <img
                      class="sliderimg"
                      src="@/assets/images/logo.svg"
                      alt=""
                    >
                  </swiper-slide>
                </Swiper>

              </div>

            </div>
            <div class="picture_board_tabstxt">
              <a-tabs
                tab-position="top"
                :style="{ height: '27px' }"
                @prevClick="callback"
                @nextClick="callback"
                class="default_tabs"
              >
                <a-tab-pane
                  :key="1"
                  :tab="`家居产品`"
                > </a-tab-pane>
                <a-tab-pane
                  :key="2"
                  :tab="`品牌推荐`"
                > </a-tab-pane>
                <a-tab-pane
                  :key="3"
                  :tab="`养生健康`"
                > </a-tab-pane>
                <a-tab-pane
                  :key="4"
                  :tab="`娱乐商城`"
                > </a-tab-pane>
                <a-tab-pane
                  :key="5"
                  :tab="`娱乐商城2`"
                > </a-tab-pane>
                <a-tab-pane
                  :key="6"
                  :tab="`娱乐商城3`"
                > </a-tab-pane>
              </a-tabs>
              <!-- <span class="tabstxt_sp">家居产品</span>
              <span class="tabstxt_sp">品牌推荐</span>
              <span class="tabstxt_sp">养生健康</span>
              <span class="tabstxt_sp">娱乐商城</span> -->
            </div>
            <div class="picture_board_goodscard">
              <div class="goods_cards_box">
                <img
                  src="@/assets/images/global-empty.png"
                  alt=""
                  class="goodsbg"
                >
                <div class="top_goodsname">{{ '娇莺啼明星产品' }} </div>
                <div class="top_goodsalname">{{ '真·回购王' }} </div>
                <div class="goodscards">
                  <div class="goodscards_items">
                    <img
                      src="@/assets/images/tree_arr.png"
                      alt=""
                      class="goodsMainimg"
                    >
                    <div class="goodsmainname">{{ '圣莉斯歌芳华系列之洁面乳' }}</div>
                    <div class="goodsmainprice"><span class="pri1">{{ '￥234' }}</span> <span class="pri2">{{ '￥289' }}</span>
                      <span class="goodAdd"><i class="meiye-icon meiye-routeicon-add-copy"></i></span>
                    </div>

                  </div>
                  <div class="goodscards_items">
                    <img
                      src="@/assets/images/tree_arr.png"
                      alt=""
                      class="goodsMainimg"
                    >
                    <div class="goodsmainname">{{ '圣莉斯歌芳华系列之洁面乳' }}</div>
                    <div class="goodsmainprice"><span class="pri1">{{ '￥234' }}</span> <span class="pri2">{{ '￥289' }}</span>
                      <span class="goodAdd"><i class="meiye-icon meiye-routeicon-add-copy"></i></span>
                    </div>

                  </div>
                  <div class="goodscards_items">
                    <img
                      src="@/assets/images/tree_arr.png"
                      alt=""
                      class="goodsMainimg"
                    >
                    <div class="goodsmainname">{{ '圣莉斯歌芳华系列之洁面乳' }}</div>
                    <div class="goodsmainprice"><span class="pri1">{{ '￥234' }}</span> <span class="pri2">{{ '￥289' }}</span>
                      <span class="goodAdd"><i class="meiye-icon meiye-routeicon-add-copy"></i></span>
                    </div>

                  </div>
                </div>
              </div>
              <img
                class="mainfootbg"
                src="@/assets/images/mainfootbg.png"
                alt=""
              >
            </div>

          </div>
        </div>
        <div class="boardContent flex-1">
          <!-- 第一步 -->
          <div
            class="one"
            v-if="step===1"
          >

            <div class="zengpinstep">
              <h2 class="twoh2 mgb-30">
                <span class="tpointer"></span>
                <span class="ttxt">首页轮播图</span>
              </h2>
              <a-row class="mgb-40 flex">
                <span class="h2leftlable">轮播图</span>
                <span class="h2rgtlable">
                  <div class=" swiper-box">
                    <upload-image
                      @getImageId="getDelImageId"
                      @delimageId="delDelimageId"
                      @getImageshow="getImageshow"
                      :multiple="6"
                      :img="form.goods_slider"
                      @draggable="handleDraggable"
                      ref="uploadCom"
                    ></upload-image>
                  </div>
                </span>
              </a-row>
              <a-row class=" ">
                <span class="h2leftlable">图片轮播时间</span>
                <span class="h2rgtlable">
                  <a-radio-group
                    name="radioGroup"
                    :default-value="1"
                    class="radio_nomal1"
                    v-model="form.minites"
                  >
                    <a-radio :value="1">
                      3秒
                    </a-radio>
                    <a-radio :value="2">
                      5秒
                    </a-radio>
                  </a-radio-group> </span>
              </a-row>

            </div>
          </div>
          <!-- 第二步 -->
          <div
            class="two"
            v-if="step==2"
          >
            <div class="zengpinstep zengpinstep2">
              <h2 class="twoh2 mgb-32">
                <span class="tpointer"></span>
                <span class="ttxt">销售类目</span>
              </h2>
              <div class="tabsbox">
                <a-tabs
                  v-if="tabslist && tabslist.length"
                  type="card"
                  class="ant_card_tabs"
                  @change="callback"
                  v-model="activeKey"
                >
                  <a-tab-pane
                    v-for="its in tabslist"
                    :key="its.category_id"
                    :tab="its.category_name"
                  >
                  </a-tab-pane>
                  <span
                    slot="tabBarExtraContent"
                    class="addtype"
                  >
                    <i
                      class="meiye-icon meiye-routeicon-add-copy"
                      @click="addtypefn"
                    ></i>
                    添加类目
                  </span>
                  <span
                    slot="tabBarExtraContent"
                    class="edittype"
                  >
                    <i
                      class="meiye-icon meiye-bianji"
                      @click="edittypefn"
                    ></i>
                    编辑类目名称
                  </span>
                </a-tabs>
                <div
                  class="tabscontent_box"
                  v-if="groupList && groupList.length && flag"
                >
                  <div
                    class="tabscontent"
                    v-for="(it,idx) in groupList"
                    :key="idx"
                  >
                    <!-- 组列表 一行  popTable3 -->
                    <div class="guigeTablebox  mgb-30">
                      <a-table
                        v-if="it && groupFlag"
                        :pagination="false"
                        :rowKey="record=>record.category_id"
                        :columns="groups_clomns"
                        :data-source="[it]"
                        class="popTable3"
                      >
                        <span slot="groupname">分组名称 <span class="redColor">*</span></span>
                        <span slot="image">背景图片 <span class="redColor">*</span></span>
                        <template
                          slot="groupname"
                          slot-scope="action, record"
                        >
                          <span v-if="!record.isEdit">{{ record.category_name || '--' }}</span>
                          <a-input
                            v-else
                            style="width:138px;"
                            class="ant-input_1"
                            allowClear
                            v-model="record.category_name"
                            @pressEnter="(e)=>changeSingeSpecIpt(e,'category_name',record)"
                          />

                        </template>
                        <!-- <template
                          slot="groupname"
                          slot-scope="action, record"
                        >
                          <a-input
                            style="width:138px;"
                            class="ant-input_1"
                            allowClear
                            v-model="record.category_name"
                            @pressEnter="(e)=>changeSingeSpecIpt(e,'category_name',record)"
                          />
                        </template> -->
                        <template
                          slot="image"
                          slot-scope="action, record"
                        >
                          <a-upload
                            :show-upload-list="false"
                            :action="baseUrl+'/upload'"
                            :headers="headers"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :before-upload="beforeUpload"
                            :customRequest="(info) =>uploadspecFiles(info,record,false,it)"
                            style=""
                          >
                            <img
                              v-if="record.category_background_image"
                              :src="record.category_background_image"
                              alt="avatar"
                            />
                            <div v-else>
                              <a-icon type='plus' />
                            </div>
                          </a-upload>
                        </template>
                        <template
                          slot="titles"
                          slot-scope="action, record"
                        >
                          <span v-if="!record.isEdit">{{ record.category_title || '--' }}</span>
                          <a-input
                            v-else
                            style="width:138px;"
                            class="ant-input_1"
                            allowClear
                            v-model="record.category_title"
                            @pressEnter="(e)=>changeSingeSpecIpt(e,'category_title',record)"
                          />
                        </template>
                        <template
                          slot="actions"
                          slot-scope="action, record"
                        >
                          <span v-if="!record.isEdit">
                            <a-button
                              size="small"
                              class="mgr-12 smallbtn"
                              @click="editGroups(record)"
                            >编辑</a-button>
                            <a-button
                              size="small"
                              class="mgr-12 smallbtn"
                              @click="deleteGroups(record)"
                            >删除</a-button>
                          </span>
                          <span v-else>
                            <a-button
                              size="small"
                              class="mgr-12 small_primary"
                              @click="saveGroups(record)"
                            >保存</a-button>
                            <a-button
                              size="small"
                              class="mgr-12 smallbtn"
                              @click="cancleGroups(record)"
                            >取消</a-button>
                          </span>
                        </template>
                      </a-table>
                    </div>
                    <!-- 商品列表 多行 -->
                    <div
                      class="guigeTablebox  mgb-30"
                      v-if="it.category_id"
                    >
                      <a-row>
                        <span class="gooslable1">商品</span> <span class="goodslable2">已选</span> <span class="goodslable3 baseColor11">{{  it.selectedRowKeys?it.selectedRowKeys.length:0 }}</span>
                        <a-button
                          type="primary"
                          class="table-btn-clk"
                          style=" z-index: 1;margin-right: 16px;height: 27px;line-height: 26px;"
                          @click="addShops(it)"
                        >
                          <i
                            class="meiye-icon meiye-routeicon-add-copy"
                            style="color:#fff;font-size:12px;margin-right:4px;"
                          ></i>
                          添加商品
                        </a-button>
                        <a-button
                          v-if="it.selectedRowKeys && it.selectedRowKeys.length"
                          class="mgl-12 normalbtn ant-btn-grounding"
                          @click="onOpen(1)"
                        >批量删除</a-button>
                      </a-row>
                      <a-table
                        v-if="it.goodsList && flag"
                        :pagination="false"
                        :rowKey="record=>record.category_goods_id"
                        :columns="goods_clomns"
                        :data-source="it.goodsList"
                        class="popTable3"
                        :row-selection="rowSelection"
                      >
                        <!--   :row-selection=" { selectedRowKeys: it.selectedRowKeys, onChange: (e)=>onSelectChange(e,it) }"
                      -->
                        <template
                          slot="goods_type"
                          slot-scope="action, record"
                        >
                          <span v-if="['guest','third_guest','shop','third_shop'].includes(record.goods_type)">商品</span>
                          <span v-else-if="['service','third_service'].includes(record.goods_type)">服务</span>
                          <span v-else-if="record.goods_type=='stored_card'">储值卡</span>
                          <span v-else-if="['rights_card','third_rights_card'].includes(record.goods_type)">卡项</span>
                          <span v-else>--</span>
                          <!--  goods_type: 1,//1,2,3,4 商品; 5服务，6合作服务; 7储值卡;8方案 ，9合作方案 -->
                          <!-- <span>{{ record.goods_type<5?'商品':(record.goods_type==4||record.goods_type==6)?'服务':record.goods_type==7?'储值卡':'卡项' }} </span> -->
                        </template>

                        <template
                          slot="actions"
                          slot-scope="action, record"
                        >
                          <a-button
                            class="normalbtn"
                            @click="deleteGoods(record)"
                            style="width:64px; height:32px;margin-right:16px"
                          >删除</a-button>
                        </template>

                      </a-table>
                    </div>
                    <!-- 添加分组 一行 -->
                    <a-row
                      class="addtracking mgt-40"
                      v-if="(groupList.length - 1) ==idx && groupList[idx].category_id &&  groupList[idx].category_id>0"
                    >
                      <span
                        class="addplus"
                        @click="addBg"
                      >
                        <i class="meiye-icon meiye-routeicon-add-copy baseColor11"></i>
                      </span>
                      <span
                        @click="addBg"
                        class="baseColor11 pointer"
                      >添加分组</span></a-row>
                  </div>
                </div>

              </div>
            </div>

          </div>

        </div>
        <div
          class="last-btns  last-btns-step2"
          v-if="step==1"
        >
          <a-row
            class="flex "
            style="margin-bottom:42px;"
          >
            <a-col :span="0">
            </a-col>
            <a-col :span="19">
              <a-space :size="20">
                <a-button
                  type="primary"
                  @click="onStep(2,true)"
                  class="stepbtnnext1   bigheightbn wid-80"
                >保存</a-button>
                <a-button
                  @click="onStep(2)"
                  class="stepbackbtn  mgr-16 bigheightbn wid-80"
                >下一步</a-button>
                <a-button
                  class="stepbackbtn bigheightbn wid-80"
                  @click="cancle()"
                >取消</a-button>
              </a-space>
            </a-col>
          </a-row>

        </div>
        <div
          class="last-btns  last-btns-step2"
          v-if="step==2"
        >
          <a-row
            class="flex "
            style="margin-bottom:42px;"
          >
            <a-col :span="0">
            </a-col>
            <a-col :span="19">
              <a-space :size="20">
                <a-button
                  class="stepbackbtn   bigheightbn wid-80"
                  @click="onStep(1)"
                >上一步</a-button>
              </a-space>
            </a-col>
          </a-row>

        </div>
      </div>
      <!-- 添加类目弹框 -->
      <a-modal
        title="添加销售类目"
        :visible="isaddtype"
        @cancel="isaddtype=false;"
        :width="600"
        class="wid_600-modal commonModel2"
        @ok="addtypeok"
        okText="保存"
      >
        <div class="adress_box memobox">
          <a-form-model
            :label-col="{ span: 3 }"
            :wrapper-col="{ span: 18 }"
            labelAlign="left"
          >

            <a-form-model-item
              label="类目名称"
              required
            >
              <a-input
                style="width:411px; "
                class="ant-input_1"
                v-model="addtypvalue"
                @pressEnter="addtypeok"
              ></a-input>
            </a-form-model-item>
          </a-form-model>
        </div>
      </a-modal>
      <!-- 编辑类目弹框 -->
      <a-modal
        title="编辑类目"
        :visible="isedittype"
        @cancel="isedittype=false;"
        :width="600"
        class="wid_600-modal commonModel2"
        :footer="null"
      >
        <div class="adress_box memobox">
          <!-- 表格 -->
          <a-table
            class="popTable3"
            :pagination="false"
            :rowKey="record=>record.category_id"
            :columns="editTable.config"
            :data-source="editTable.tableData"
          >
            <template
              slot="_1"
              slot-scope="action, record"
            >
              <span v-if="!record.isEidt">{{ record.category_name }}</span>
              <a-input
                v-else
                class="ant-input_1"
                v-model="record.category_name"
                style="width:283px;margin-right:16px"
                placeholder="输入分类名称，10个字以内"
                :maxLength="10"
              ></a-input>
              <!-- @pressEnter=";updataCa(record)" -->
            </template>
            <template
              slot="_2"
              slot-scope="action, record"
            >
              <span v-if="record.isEidt">
                <a-button
                  size="small"
                  class="mgr-12 small_primary"
                  @click="savetype(record)"
                >保存</a-button>
                <a-button
                  size="small"
                  class="mgr-12 smallbtn"
                  @click="cancletype(record)"
                >取消</a-button>
              </span>
              <span v-else>
                <a-button
                  size="small"
                  class="mgr-12 smallbtn"
                  @click="editType(record)"
                >编辑</a-button>
                <a-button
                  size="small"
                  class="mgr-12 smallbtn"
                  @click="deletetype(record)"
                >删除</a-button>
              </span>

            </template>
          </a-table>
        </div>
      </a-modal>
      <!-- 添加商品 -->
      <a-modal
        title="添加商品"
        :visible="consumable.visible"
        @cancel=";consumable.visible=false;"
        :width="1210"
        @ok="addconsumable"
        class="oversizeModal"
      >
        <div class="limits_body">
          <!-- 复合搜索框 -->
          <ComposeInput
            :selectArr="selectArr3"
            placeholderValue="输入关键字"
            @getList="getListSearchlimits"
            :styles="typestyles2"
          ></ComposeInput>
          <!-- 穿梭框 -->
          <div class="transferBox">
            <div class="transferleftips">
              <a-select
                default-value="商品"
                placeholder="请选择类型"
                allowClear
                v-model="consumable.goods_type"
                style="width: 198px"
              >
                <a-select-option value="1">
                  商品
                </a-select-option>
                <a-select-option value="2">
                  服务
                </a-select-option>
                <a-select-option value="3">
                  储值卡
                </a-select-option>
                <a-select-option value="4">
                  卡项
                </a-select-option>
              </a-select>
            </div>
            <div class="transferighgtips">
              <span class="topsline  mgr-8"></span>
              <span class="mgr-8">已选商品</span>
              <span class="nomaltxt">{{consumable.targetKeys.length}}条</span>
            </div>
            <a-transfer
              :data-source="consumable.mockData"
              :target-keys="consumable.targetKeys"
              :render="record => record.title"
              :show-select-all="false"
              @change="onlimitsChange"
            >
              <!-- :selected-keys="consumable.selectedRowKeys" -->
              <template
                slot="children"
                slot-scope="{ props: { direction, filteredItems, selectedKeys, disabled: listDisabled }, on: { itemSelectAll, itemSelect }, }"
              >
                <!-- selectedKeys, -->
                <a-table
                  :pagination="false"
                  :row-selection="
                 getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })"
                  :columns="direction === 'left' ? consumable.leftColumns : consumable.rightColumns"
                  :data-source="filteredItems"
                  size="small"
                  :style="{ pointerEvents: listDisabled ? 'none' : null }"
                >
                  <template
                    slot="titles"
                    slot-scope="action, record"
                  >
                    <div class="box_tit flex  ">
                      <img
                        v-if="record.goods_image"
                        class="boxtit_left"
                        :src="record.goods_image"
                      />
                      <span v-else>暂无图片</span>
                      <div class="boxtit_rgt">
                        <p class="boxtit_rgt_top">{{ record.goods_name }}</p>
                        <p class="boxtit_rgt_bot">商品码： {{ record.goods_bn }}</p>
                      </div>
                    </div>
                    <!-- <a-tooltip>
                      <template slot="title">
                        <span>{{ record.title }}</span>
                      </template>
                      <span>{{ record.title }}</span>
                    </a-tooltip> -->
                  </template>
                  <template
                    slot="product_bn"
                    slot-scope="action, record"
                  >
                    <span
                      style="margin-left:12px;"
                      v-if="['guest','third_guest','shop','third_shop'].includes(record.goods_type)"
                    >商品</span>
                    <span
                      style="margin-left:12px;"
                      v-else-if="['service','third_service'].includes(record.goods_type)"
                    >服务</span>
                    <span
                      style="margin-left:12px;"
                      v-else-if="record.goods_type=='stored_card'"
                    >储值卡</span>
                    <span
                      style="margin-left:12px;"
                      v-else-if="['rights_card','third_rights_card'].includes(record.goods_type)"
                    >卡项</span>
                    <span
                      style="margin-left:12px;"
                      v-else
                    >--</span>
                    <!-- <span style="margin-left:12px;"> {{record.goods_type<5?'商品':record.goods_type==5||record.goods_type==6?'服务':record.goods_type==7?'储值卡':'方案'}}</span> -->

                  </template>
                  <template
                    slot="goods_price"
                    slot-scope="action, record"
                  >
                    <span style="margin-left:12px;">￥ {{ record.goods_price }}</span>
                  </template>
                </a-table>
              </template>
            </a-transfer>
          </div>
        </div>
      </a-modal>

    </div>
  </div>

</template>
<script>
import moment from 'moment';
import config from '../config';
import {
  // GetmanageGoodslist,
  // GetgoodsoptionsList, //门店分类
  // TreetypeFinanceList,//财务分类
  // TreetypeMallList,//商城分类
  // CreateGoods, UpdateGoods, GoodsInfo, GetgoodsbrandsoptionsList,
  // Allproduct
  Getswippers,//获取首页轮播图 
  Saveswippers,//保存首页轮播图
  GetGoodslist,//商品列表
  AddTtypes,// 新增类目/分组
  getTypestree,// 类目分组树形结构
  AddgroupsGoods,// 分组添加商品
  GetgroupsGoods,// 分组商品数据
  UpdategroupsGoods,// 修改类目/分组
  DetelegroupsGoods,// 删除关联商品
  Deteletypes, // 删除类目/分组
  Savegoods,//分组保存商品
} from '@/api/mall'
import UploadImage from '../modules/drag'
import ShopEditor from '../modules/wangeditor'
import skuSetting from '../../../components/intimate/skuSetting/index.vue'
import Msg from '@/components/mrj-menu/msg'
import storage from 'store'
import axios from 'axios'
import { pinyin } from 'pinyin-pro';
import ComposeInput from '@/components/compose_input'
import difference from 'lodash/difference';

import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";



const leftTableColumns = [
  {
    dataIndex: 'titles',
    title: '商品信息',
    key: 'titles',
    ellipsis: true,
    width: 180,
    scopedSlots: { customRender: 'titles' },
  },
  {
    dataIndex: 'product_bn',
    title: '商品类型',
    key: 'product_bn',
    scopedSlots: { customRender: 'product_bn' },
  },
  {
    // slots: { title: 'product_attr' },
    dataIndex: 'goods_price',
    title: '商城售价',
    key: 'goods_price',
    scopedSlots: { customRender: 'goods_price' },
  },//
  {
    dataIndex: 'goods_sales',
    title: '实际销量',
    key: 'goods_sales',
    scopedSlots: { customRender: 'goods_sales' },
  },//实际销量
];
const rightTableColumns = [
  {
    dataIndex: 'title',
    title: '产品名称',
    key: 'titles',
    ellipsis: true,
    scopedSlots: { customRender: 'titles' },
  },
  {
    dataIndex: 'product_bn',
    title: '商品码',
    key: 'product_bn',
    scopedSlots: { customRender: 'product_bn' },
  },
  {
    slots: { title: 'product_attr' },
    dataIndex: 'product_attr',
    // title: '产品SKU',
    key: 'product_attr',
    scopedSlots: { customRender: 'product_attr' },
  },
  {
    slots: { title: 'goods_consume' },
    dataIndex: 'goods_consume',
    // title: '消耗量',
    key: 'goods_consume',
    scopedSlots: { customRender: 'goods_consume' },
  },
];
export default {
  inject: ['reload'],
  components: {
    UploadImage, ShopEditor, skuSetting
    , Swiper, SwiperSlide, ComposeInput
  },
  data() {
    return {
      config,
      setId: '',
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      headers: {
        "Authorization": localStorage.getItem("token"),
        "Content-Type": 'multipart/form-data'
      },


      selectreeObj: {
        children: 'children',
        label: 'category_name',
        value: 'category_id'
      },
      selectreeObj2: {
        children: 'children',
        label: 'category_name',
        value: 'finance_category_id'
      },
      selectreeObj3: {
        children: 'children',
        label: 'category_name',
        value: 'store_category_id'
      },
      brandsList: [],//品牌列表
      typesList: [
        { name: '客装', id: 1 },
        { name: '院装', id: 2 },
      ],//类别
      isactive: false,
      // 规格相关
      goods_specs: {
        isSingle: 1,//1  1单规格 2多规格
        barcode: undefined,//批量设置货品码
        price: undefined,//批量设置单价
        product_market_price: undefined,//批量市场价
        product_image: undefined,//批量设置图片
        ischooseModehow: true,//选择规格model
        goods_attr: [
          // { attr_name: '', attr_values: '' ,id:0}
        ],
        goods_specs_id: undefined,
        goods_attr_val: [
        ],
        goods_specs_list: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        goods_specs_list0: [
          {
            attr_name: '颜色', attr_values: ['红色', '白色'], id: 0
          },
          {
            attr_name: '容量', attr_values: ['500ml', '250ml'], id: 1
          },
          {
            attr_name: '量装', attr_values: ['袋装', '瓶装'], id: 2
          },
          {
            attr_name: '新增规格', attr_values: [], id: 9999
          },
        ],
        // 总共的 总规格list
        new_goods_specs_list: [
        ],
        goods_specs_values_list: [],
        goods_specs_keys_list: [],
        // select暂存的规格 和规格值 确认之后才push
        goods_specs_selectobj: {

        }
      },
      ischangeGoodsSpecs1: true, //是否显示下拉框所属的规格
      showaddGoodsprivatespec: false,//自定义新增规格弹框
      singelguigeadd: {
        attr_name: '',
        attr_values: [],
      },
      // 表单头
      goods_specs_clomns: [
        {
          title: '规格图',
          dataIndex: 'product_image',
          key: 'product_image',
          className: 'pdl-24',
          ellipsis: true,
          scopedSlots: { customRender: 'product_image' },
        },
        {
          // title: '*标准单价',
          slots: { title: 'product_price' },
          dataIndex: 'product_price',
          key: 'product_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_price' },
        },

        {
          // title: '*货品码',
          slots: { title: 'product_bn' },
          dataIndex: 'product_bn',
          key: 'product_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'product_bn' },
        },
        {
          title: '市场价',
          dataIndex: 'product_market_price',
          key: 'product_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'product_market_price' },
        },
      ],
      goodSpecstableData: [],
      goodSpecsSingletableData: [
        {
          product_price: undefined,
          product_market_price: undefined,
          product_image: undefined,
          product_bn: undefined,
          product_attr: '',
          product_is_def: 1,
          product_id: 0,
          product_cost_price: '',
          product_market_price: '',
          product_stock: ''
        }
      ],
      // 固定的规格和规格值
      forevergoodSpecsObj: {
        activegoodSpecs: undefined,
        forevergoodSpecsList: [
          { name: '部位', id: '1', children: [{ name: '全身', id: '2' }, { name: '背部', id: '3' }, { name: '面部', id: '4' }] },
          { name: '颜色', id: '5', children: [{ name: '红色', id: '6' }, { name: '白色', id: '7' }, { name: '黑色', id: '8' }] },
          { name: '尺寸', id: '9', children: [{ name: '大', id: '10' }, { name: '中', id: '11' }, { name: '小', id: '12' }] },
          { name: '新建规格', id: '9999' }
        ],
        // 
      },
      addorupdateSpecFlag: 'add',//是修改规格还是编辑 编辑的时候只能添加规格值
      isShowGuigeAddSelect: true,
      form: {//表单数据
        goods_id: undefined,
        goods_name: undefined,
        goods_bn: undefined,
        goods_unit: undefined,
        is_give: 0,//0不允许 
        is_show: 1,
        goods_type_hz: 0,//合作项目 需要联动 goods_type   
        goods_attr: [],
        goods_attr_val: [{ product_image: '', product_price: '', product_bn: "" }],
        goods_desc: undefined,
        goods_image: undefined,
        goods_slider: [],
        minites: 1,
        goods_type: 'guest',
        goods_supplier: 1,
        goods_brand: undefined,
        finance_category_id: [],
        store_category_id: [],
        goods_category: [],
        goods_content: undefined,
        goods_shou_type: 1,
        goods_shou_end_time: null
      },
      // goods_bn: '',//自动生成首字母的
      step: 1,
      imgflag: false,
      permissionInfo: {
        visible: false,
        text: '提交',
        title: '',
        type: 1,//1新增   3编辑
        pic_url_loading: false,
        url_loading: false,
        isDisabled: false,
        info: {
          permission_id_list: [],
          goods_type: undefined,//商品类型
          goods_category: undefined,//商品分类
          goods_brand: undefined,//商品品牌
          goods_bn: undefined,
          goods_id: undefined,
          goods_name: undefined,
          goods_supplier: 1,
          goods_unit: undefined,// 单位
          is_show: 1,// 商品状态
          is_del: 0,//是否删除
          goods_image: undefined,
          goods_slider: [],//轮播图
          minites: 1,//轮播秒数
          goods_attr: [],//商品规格
          goods_attr_val: [],//商品规格值 
          goods_content: '<h1>q<strong>wqeddd</strong><span style=\"background-color: rgb(196, 29, 127);\">dxxxxx</span></h1><p>撒打 撒啊大</p>',//商品描述
          goods_desc: undefined,//商品简介
        }
      },
      swiperOption: {
        // some swiper options/callbacks
        // 所有的参数同 swiper 官方 api 参数
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        slidesPerView: 1,
        // 设置自动轮播
        autoplay: {
          delay: 1000, //1秒切换一次
          disableOnInteraction: false
        },
        // 设置轮播可循环
        loop: true
      },

      tabslist1: [
        // {
        //   category_background_image: '',
        //   category_id: 1,
        //   category_name: "销售目录1",
        //   category_parent: 0,
        //   category_status: 1,
        //   category_title: "标题",
        //   children: [{
        //     category_background_image: "url",
        //     category_id: 3,
        //     category_name: "分组2",
        //     category_parent: 1,
        //     category_status: 1,
        //     category_title: "标题",
        //     created_at: "2023-07-12 08:33:22",
        //     updated_at: "2023-07-12 08:33:22",
        //   }],
        //   created_at: "2023-07-12 08:29:04",
        //   updated_at: "2023-07-14 09:53:35",
        // },
        // {
        //   category_background_image: '',
        //   category_id: 2,
        //   category_name: "销售目录2",
        //   category_parent: 0,
        //   category_status: 1,
        //   category_title: "标题",
        //   created_at: "2023-07-12 08:29:04",
        //   updated_at: "2023-07-14 09:53:35",
        // },

        // {
        //   type: 1,
        //   typemane: '优选推荐',
        //   groupList: [
        //     {
        //       group: 1, groupname: '组名一',
        //       image: 'https://sandbox.static.meirenji.vip/hly_unknow//2023/07/06/64a68bf0e45aa.png',
        //       titles: '标题一',
        //       goodsList: [
        //         {
        //           goods_id: 137,
        //           goods_bn: "cp001",
        //           goods_market_price: "0.00",
        //           goods_name: "产品001",
        //           goods_price: "11.00",
        //           goods_type: 1,//1,2,3,4 商品; 5服务，6合作服务; 7储值卡;8方案 ，9合作方案
        //         },
        //       ],
        //       selectedRowKeys: [137],
        //     },
        //     {
        //       group: 2, groupname: '组名2',
        //       image: 'https://sandbox.static.meirenji.vip/hly_unknow//2023/07/06/64a68bf0e45aa.png',
        //       titles: '标题2',
        //       goodsList: [
        //         {
        //           goods_id: 137,
        //           goods_bn: "cp001",
        //           goods_market_price: "0.00",
        //           goods_name: "产品001",
        //           goods_price: "11.00",
        //           goods_type: 5,//1,2,3,4 商品; 5服务，6合作服务; 7储值卡;8方案 ，9合作方案
        //         },

        //       ],
        //       selectedRowKeys: [137],
        //     }
        //   ],
        // },
        // {
        //   type: 2,
        //   typemane: '家具产品',
        //   groupList: [
        //     {
        //       group: 1, groupname: '组名一',
        //       image: 'https://sandbox.static.meirenji.vip/hly_unknow//2023/07/06/64a68bf0e45aa.png',
        //       titles: '标题二',
        //       goodsList: [
        //         {
        //           goods_id: 141,
        //           goods_bn: "cp001",
        //           goods_market_price: "0.00",
        //           goods_name: "产品001",
        //           goods_price: "11.00",
        //           goods_type: 5,//1,2,3,4 商品; 5服务，6合作服务; 7储值卡;8方案 ，9合作方案
        //         },

        //       ],
        //       selectedRowKeys: [137],
        //     }
        //   ],
        // },
        // {
        //   type: 3,
        //   typemane: '明星产品',
        //   groupList: [
        //     {
        //       group: 1, groupname: '组名一',
        //       image: 'https://sandbox.static.meirenji.vip/hly_unknow//2023/07/06/64a68bf0e45aa.png',
        //       titles: '标题三',
        //       goodsList: [
        //         {
        //           goods_id: 141,
        //           goods_bn: "cp001",
        //           goods_market_price: "0.00",
        //           goods_name: "产品001",
        //           goods_price: "11.00",
        //           goods_type: 5,//1,2,3,4 商品; 5服务，6合作服务; 7储值卡;8方案 ，9合作方案
        //         },

        //       ],
        //       selectedRowKeys: [137],
        //     }
        //   ],
        // },
      ],
      groups_clomns: [
        {
          // title: '分组名称',
          slots: { title: 'groupname', },
          dataIndex: 'groupname',
          key: 'groupname',
          ellipsis: true,
          scopedSlots: { customRender: 'groupname' },
        },
        {
          // title: '背景图片',
          slots: { title: 'image', },
          dataIndex: 'image',
          key: 'image',
          ellipsis: true,
          scopedSlots: { customRender: 'image' },
        },
        {
          title: '标题',
          dataIndex: 'titles',
          key: 'titles',
          ellipsis: true,
          scopedSlots: { customRender: 'titles' },
        },
        {
          title: '操作',
          dataIndex: 'actions',
          key: 'actions',
          ellipsis: true,
          scopedSlots: { customRender: 'actions' },
        }
      ],
      goods_clomns: [
        {
          title: '商品',
          dataIndex: 'goods_name',
          key: 'goods_name',
          ellipsis: true,
          scopedSlots: { customRender: 'goods_name' },
        },
        {
          title: '商品码',
          dataIndex: 'goods_bn',
          key: 'goods_bn',
          ellipsis: true,
          scopedSlots: { customRender: 'goods_bn' },
        },
        {
          title: '商品类型',
          dataIndex: 'goods_type',
          key: 'goods_type',
          ellipsis: true,
          scopedSlots: { customRender: 'goods_type' },
        },//
        {
          title: '商城售价',
          dataIndex: 'goods_market_price',
          key: 'goods_market_price',
          ellipsis: true,
          scopedSlots: { customRender: 'goods_market_price' },
        },
        {
          title: '实际销量',
          dataIndex: 'goods_price',
          key: 'goods_price',
          ellipsis: true,
          scopedSlots: { customRender: 'goods_price' },
        },
        {
          title: '操作',
          dataIndex: 'actions',
          key: 'actions',
          ellipsis: true,
          scopedSlots: { customRender: 'actions' },
        }
      ],

      consumable: {
        visible: false,
        category_id: undefined,
        originalmockData: [],
        productPage: 1,
        ztotal: undefined,
        num: 1,//判断提交时候是单双规格的确定
        record: '',//暂存的规格列
        mockData: [],
        targetKeys: [],
        leftColumns: leftTableColumns,
        rightColumns: leftTableColumns,
        limitsreplaceFields: {
          key: "id",
          title: 'title'
        },
        goods_type: undefined,
        zcSelectedTitles: [],//批量设置暂存 title
        consumableList: [],    // 批量设置暂存 耗材
        expandedRowKeys: [],//控制选中
        selectedRowKeys: [],//选中项
        panigation: {
          newCurrent: 1,
          newTotol: 50,
        },
        newdatasorce: [],
        number: 1,

      },
      selectArr3: [
        { name: '商品名称', id: '1' },
        { name: '商品码', id: '2' },
      ],
      typestyles2: {
        width: '506px',
        height: "32px"
      },
      groupList: [
        {
          category_background_image: "url",
          category_id: 3,
          category_name: "分组2",
          category_parent: 1,
          category_status: 1,
          category_title: "标题",
          created_at: "2023-07-12 08:33:22",
          updated_at: "2023-07-12 08:33:22",
          goodsList: [
            {
              category_goods_id: 14,
              category_id: 3,
              created_at: "2023-07-18 07:57:08",
              goods_bn: "servec1",
              goods_id: 7,
              goods_name: "服务名称",
              goods_price: "333.00",
              goods_sales: 0,
              goods_type: 'service',
              sort: 1,
              updated_at: "2023-07-18 07:57:08",
            },
          ],
          selectedRowKeys: [],
        },
        {
          category_background_image: "url3",
          category_id: 6,
          category_name: "分组3",
          category_parent: 1,
          category_status: 1,
          category_title: "标题3",
          created_at: "2023-07-12 08:33:22",
          updated_at: "2023-07-12 08:33:22",
          selectedRowKeys: [],
          goodsList: []
        },
      ],//
      selectedKeys: [],//选中的商品keys
      activeKey: undefined,//tabs的value项
      isaddtype: false,//是否添加类
      addtypvalue: undefined,//添加的类名
      tabslist: [],
      isedittype: false,//是否编辑类
      editTable: {
        tableData: [
        ],
        config: [
          {
            title: '类目名称',
            dataIndex: 'category_name',
            key: '_1',
            ellipsis: true,
            width: 400,
            scopedSlots: { customRender: '_1' },
          },
          {
            title: '操作',
            dataIndex: 'action',
            key: '_2',
            ellipsis: true,
            scopedSlots: { customRender: '_2' },
          },

        ],
        isShow: false,
      },
      flag: true,
      groupFlag: true,
    }
  },
  async created() {
    // 获取首页轮播图
    this.getSwipper()
    //初始化tabs数据（第二步） 
    this.getTreeListFn()

  },
  methods: {
    moment,
    // 获取详情信息
    getPartnerDetail(id) {
      partnerDetail({ id: id }).then(res => {
        console.log(res.data, "data");
        let list_no = [];
        let list_yes = [];
        res.data.commission_rule.forEach(element => {
          if (element.product_type == 1) {
            list_no.push(element);
          }
          if (element.product_type == 2) {
            list_yes.push(element);
          }
        });
        this.form = {
          ...res.data,
          share_price: res.data.subscription_setting.share_price,
          min_shares: res.data.subscription_setting.min_shares,
          premium_rate: res.data.subscription_setting.premium_rate,
          commission_rule_no: list_no,
          commission_rule_yes: list_yes,
        };
        // this.timeData = [res.data.start_date, res.data.end_date];
        this.application_timeData = [res.data.application_start_date, res.data.application_end_date]
        // console.log('this.timeData :>> ', this.timeData);
        console.log('this.form :>> ', this.form);
      })
    },
    // 获取方案 id
    getPartnerGenerated() {
      partnerGenerated().then((res) => {
        this.form.scheme_identifier = res.data.scheme_identifier;
      })
    },
    // 富文本
    getUser(e) {
      console.log(e);
      this.form.goods_content = e
    },
    //上传
    handleChange1(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_attr_val[0].product_image = response?.data?.url
        this.permissionInfo.url_loading = false
      }
    },
    handleChange2(info) {
      if (info.file.status === 'uploading') {
        this.permissionInfo.pic_url_loading = true
        return
      }
      if (info.file.status === 'done') {
        const { response = {} } = info.file
        this.form.goods_image = response?.data?.url
        this.permissionInfo.pic_url_loading = false
      }
    },
    beforeUpload(file, fileList) {
      var self = this
      return new Promise((resolve, reject) => {
        const isLt1M = file.size / 1024 / 1024 > 1
        if (isLt1M) {
          self.$message.error('上传文件大于1MB!')
          reject(false)
        }
        if (fileList.length > 1) {
          self.$message.error('只能上传一张图片！')
          reject(false)
        }
        var fileNames = file.name.split('.')
        var fileType = fileNames[fileNames.length - 1].toLocaleLowerCase()
        var extList = ['jpg', 'png', 'jpeg']
        if (!extList.find((item) => item == fileType)) {
          self.$message.error('只能上传.jpg/.png/.jpeg类型的图片！')
          reject(false)
        }
        resolve(true)
      }).finally(() => {
      })
    },
    // 方案期效
    // onChangeTime(rec) {
    //   console.log('rec :>> ', rec);
    //   this.form.start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
    //   this.form.end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    //   console.log('rec :>> ', this.form.start_date);
    //   console.log('rec :>> ', this.form.end_date);
    // },
    // 开放时间
    onChangeApplication(rec) {
      console.log('rec :>> ', rec);
      this.form.application_start_date = rec[0] ? rec[0].format('YYYY-MM-DD') : '';
      this.form.application_end_date = rec[1] ? rec[1].format('YYYY-MM-DD') : '';
    },
    onChangeIdentify(rec) {
      console.log('rec :>> ', rec);
      this.form.identify_end_date = rec ? rec.format('YYYY-MM-DD') : '';
    },
    handleOpenChange(status) {
      this.yearPickShow = status;
    },
    onChangeYear(rec) {
      console.log('rec :>> ', rec.format('YYYY'));
      this.form.dividend_ratio_year = rec ? rec.format('YYYY') : '';
      this.yearPickShow = false;
    },

    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
    onStep(key, flag) {
      if (key == 2) {
        //  保存轮播图
        // form.goods_slider  form.minites
        let obj = {
          carousel_data: this.form.goods_slider,
          stay_time: this.form.minites == 1 ? 3 : 5
        }
        Saveswippers(obj).then(res => {
          if (res.error_code == 0) {
            if (!flag) { this.step = key } else {
              this.$message.success('保存成功！')
            }
          }

        })

      } else {
        this.step = key
      }

    },
    //取消
    cancle() {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '当前已填写数据尚未保存，确定取消保存吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.$router.push({ path: '/mall/operationmanagement/applet' })
          Msg.$emit('changethirdaryShow', true)
        },
        onCancel() { },
      })

    },
    handlePreview(src, type) { },
    addTableItem(key, type) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this['edit_item'] = undefined
      if (key == 'dividend_ratio_list') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          dividend_business: undefined,
          dividend_rate: undefined,
          id: new Date().getTime(),
        })
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        this.form[key].splice(0, 0, {
          editable: true,
          operate: this.operate,
          first_purchase_rate: undefined,
          payment_method: undefined,
          repurchase_rate: undefined,
          product_type: type,
          id: new Date().getTime(),
        })
      }
      console.log('this.form[key] :>> ', this.form[key]);
    },
    saveItem(record, index, key) {
      let self = this
      if (key == 'dividend_ratio_list') {
        console.log(record, index, key)
        const { dividend_rate } = record
        const specValueAntDElText = self.$refs['SpecValueRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!dividend_rate) {
          msg = '分红比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      if (key == 'commission_rule_no' || key == 'commission_rule_yes') {
        const { first_purchase_rate, repurchase_rate } = record
        const specValueAntDElText = self.$refs['productTypeRef_' + index].$el.innerText
        console.log(specValueAntDElText)
        let msg
        if (specValueAntDElText.includes('选择类型')) {
          msg = '请选择类型！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!first_purchase_rate) {
          msg = '首购付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
        if (!repurchase_rate) {
          msg = '再次付款分佣比例不能为空！'
          self.$notification['error']({
            message: '错误',
            description: msg
          })
          return
        }
      }
      setTimeout(() => {
        self.form[key][index].editable = false
      }, 400)
    },
    editItem(id, key) {
      if (this.form[key].some((it) => it.editable)) {
        this.$message.warning('请完成正在编辑的条目操作！')
        return
      }
      this.form[key] = this.form[key].map((it) => {
        if (it.id == id) {
          this['edit_item'] = it
          it.editable = true
        }
        return it
      })
    },
    cancelItem(index, key) {
      if (!this['edit_item']) {
        this.form[key].splice(index, 1)
        return
      }
      this.form[key] = this.form[key].map((it, ind) => {
        if (index == ind) {
          it.editable = false
        }
        return it
      })
    },
    deleteItem(record, index, key) {
      this.form[key].splice(index, 1)
    },
    editItemQy(record) {
      this.modal.form = record;
      this.qyStep = 1;
      this.modal.visible = true;
    },
    deleteItemQy(index) {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '是否删除？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.form.benefit.splice(index, 1)
        },
        onCancel() { },
      })
    },
    // 添加权益
    handleOk() {
      if (!this.modal.form.name) return this.$message.warning('请填写权益名称！')
      if (!this.modal.form.goods_content) return this.$message.warning('请填写权益详情！')
      this.modal.form.count = this.modal.form.count_type == 1 ? 0 : this.modal.form.count;
      this.form.benefit.push(this.modal.form);
      this.modal.visible = false;
      this.qyStep = 1
    },
    handleCancel() {
      this.qyStep = 1;
      this.modal.visible = false
    },
    concatFunction(arr1, arr2) {
      //不要直接使用var arr = arr1，这样arr只是arr1的一个引用，两者的修改会互相影响  
      var arr = arr1.concat();
      //或者使用slice()复制，var arr = arr1.slice(0)  
      for (var i = 0; i < arr2.length; i++) {
        arr.indexOf(arr2[i]) === -1 ? arr.push(arr2[i]) : 0;
      }
      console.log('concatFunction', arr)
      return arr
    },
    submit() {

      if (this.form.is_all_shop == 2) {
        if (!this.withBrandCheck.length) return this.$message.warning('请选择指定门店！')
      }
      console.log('this.withBrandCheck :>> ', this.withBrandCheck);
      this.form.institution_ids = this.is_all_shop == 1 ? 'all' : this.withBrandCheck.toString();
      const { commission_rule_no, commission_rule_yes } = this.form;
      console.log('commission_rule_no :>> ', commission_rule_no);
      console.log('commission_rule_yes :>> ', commission_rule_yes);
      let arr = this.concatFunction(commission_rule_no, commission_rule_yes)
      let data = {
        ...this.form,
        commission_rule: arr,
      }
      delete data.commission_rule_no;
      delete data.commission_rule_yes;
      const hide = this.$message.loading('正在保存..', 0)
      if (this.$route.query.type == 2) {
        partnerSave(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
      if (this.$route.query.type == 1) {
        partnerAdd(data).then((res) => {
          this.cancel()
        }).finally(r => {
          hide()
        })
      }
    },
    goStep(e) {
      if (this.$route.query.type != 1) {
        this.step = e
      }
    },
    changeRoute() {
      this.$router.replace({ path: this.$route.fullpath ? this.$route.fullpath : this.$route.path });
      let newobject = storage.get('seconderyMenuObj')
      Msg.$emit('getpathname', newobject)

      this.$forceUpdate();
    },
    uploadFilesToServer(uploadApiUrl, fileName, files) {
      let formData = new FormData();
      formData.append(fileName, files);
      //添加请求头
      const headers = {
        "Content-Type": "multipart/form-data",
      };
      //配置头
      const request = axios.create({
        headers: headers,
      });
      //开始上传
      return request
        .post(uploadApiUrl, formData)
        .then((response) => {
          return Promise.resolve(response.data);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    },

    // 改变 某一个单价/条码
    changeSingeSpecIpt(record, i, it, flag) {

      console.log(record, i, it, "val,record");
      let that = this
      console.log(this.groupList, 'tabslist')
      let obj = {
        category_id: it.category_id,
        category_name: it.category_name,
        category_title: it.category_title,
        category_background_image: it.category_background_image,
        category_parent: this.activeKey,
      }
      if (!flag) {
        obj[i] = record.target.value
      } else {
        obj.category_background_image = record
        obj.category_name = it.category_name
      }
      if (it.category_id) {
        if (!it.category_name) {
          this.$message.error('请先填写组名')
          return false
        }
        UpdategroupsGoods(obj).then(res => {
          if (res.error_code == 0) {
            this.$message.success('操作成功')
            this.getTreeListFn()

          }
        })
      } else {
        if (!obj.category_name) {
          this.$message.error('请先填写组名')
          return false
        }
        AddTtypes(obj).then(res => {
          console.log(res);
          if (res.error_code == 0) {
            this.$message.success('操作成功')
            this.getTreeListFn()

          }
        })
      }

      // that.groupList.forEach((el, idx) => {
      //   if (el.category_id == record.category_id) {
      //     that.groupList[idx][i] = record[i]
      //   }
      // })

    },

    // 规格图
    async uploadspecFiles(info, record, flag, it) {
      if (!record.category_name) {
        this.$message.error('请先填写组名')
        return false
      }
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      let that = this
      if (res.error_code == 0) {
        if (flag) {
        } else {
          // that.changeSingeSpecIpt(false, false, record, true)
          // that.groupList.forEach((el, idx) => {
          //   if (el.category_id == it.category_id) {
          //     that.groupList[idx].category_background_image = res.data.url

          //   }
          // })
          that.changeSingeSpecIpt(res.data.url, false, record, true)
        }
        console.log(that.groupList);
      } else {
        this.$message.error(res.error.message)
      }
    },
    // 轮播图
    getDelImageId(val) {
      if (this.form.goods_slider && this.form.goods_slider.length > 0) {

      } else {
        this.form.goods_slider = []
      }
      this.form.goods_slider.push(val)
    },
    getImageshow(val) {
      this.pulsshow = val
    },
    delDelimageId(index) {
      this.form.goods_slider.forEach((val, key) => {
        if (index === key) {
          this.form.goods_slider.splice(key, 1)
        }
      })
    },
    // 获取到重新排序后的图片
    handleDraggable(e) {
      const imgDrag = []
      for (var i = 0; i < e.length; i++) {
        // var a = e[i].url.split('/')
        imgDrag.push(e[i].url)
      }
      this.form.goods_slider = imgDrag
    },
    // 商品 f封面图
    async ffuploadFiles(info) {
      const fileInfo = {
        uid: info.file.uid,
        name: info.file.name,
        status: "uploading",
        response: "",
        url: "",
      };
      let uploadApiUrl = this.baseUrl + '/upload'
      const res = await this.uploadFilesToServer(
        uploadApiUrl,
        "file",
        info.file
      );
      if (res.error_code == 0) {
        this.form.goods_image = res.data.url
      } else {
        this.$message.error(res.error.message)
      }
    },


    callback(val) {
      // console.log(val);
      // 切换 tabslist下的商品 1匹配  
      if (this.tabslist) {
        this.tabslist.forEach(el => {
          if (el.category_id == val) {
            this.groupList = el.children
          }
        })
      }
      // 匹配 this.groupList 的商品
      if (this.groupList) {
        this.matchGoods(val, this.groupList)
      }

    },
    //商品多选
    onSelectChange(selectedRowKeys, i) {
      let that = this
      console.log(selectedRowKeys, i);
      that.groupList.forEach((el, idx) => {
        if (el.category_id == i.category_id) {
          that.groupList[idx].selectedRowKeys = selectedRowKeys
        }
      })
      console.log(that.groupList);
      // this.table.selectedRowKeys = selectedRowKeys
      // this.table.selectedRows = selectedRows
    },
    // 添加分组
    addBg() {
      let num = this.groupList[this.groupList.length - 1].group + 1
      let obj = {
        category_background_image: "",
        category_name: "",
        category_parent: undefined,
        category_status: undefined,
        category_title: undefined,
        created_at: undefined,
        updated_at: undefined,
        selectedRowKeys: [],
        goodsList: [],
      }
      this.groupList.push(obj)
    },
    // 添加类目
    addtypefn() {
      this.isaddtype = true
    },
    edittypefn() {
      this.isedittype = true
      // this.tabslist.forEach(el => {
      //   this.editTable.tableData.push({
      //     category_name: el.category_name,
      //     category_id: el.category_id,
      //     isEidt: false
      //   })
      // })
      // this.editTable.tableData = this.tabslist
    },
    addtypeok() {
      let obj = {
        category_name: this.addtypvalue
      }
      AddTtypes(obj).then(res => {
        console.log(res);
        if (res.error_code == 0) {
          this.$message.success('操作成功')
          this.getTreeListFn()
          this.isaddtype = false
        }
      })
      // this.tabslist.push(obj)
      // todo  添加接口  获取详情接口


    },


    // 获取类目分组结果欧
    getTreeListFn() {
      let that = this
      getTypestree().then(res => {
        console.log(res);
        if (res.error_code == 0) {
          console.log(res.data);
          if (res.data) { that.tabslist = JSON.parse(JSON.stringify(res.data)) }
          console.log(that.tabslist);
          if (that.tabslist[0] && that.tabslist[0].children && that.tabslist[0].children.length) {
            that.tabslist[0].children.forEach(el => {
              el['goodsList'] = []
              el['selectedRowKeys'] = []
              el['isEdit'] = false
            })
            that.groupList = that.tabslist[0].children
            that.activeKey = that.tabslist[0].category_id

            // console.log(that.groupList, '/groupListgroupListgroupList');
            // 匹配第一个类目下的商品
            this.matchGoods(this.tabslist[0].category_id, this.groupList)
          }
          this.editTable.tableData = []
          this.tabslist.forEach(el => {
            this.editTable.tableData.push({
              category_name: el.category_name,
              category_id: el.category_id,
              isEidt: false
            })
          })

        }
      })
    },
    async matchGoods(id, arr) {  //类目 组 查询下面的商品
      let that = this
      // 请求数据  GetgroupsGoods  { category_id:1  }
      this.groupList.forEach((el, idx) => {
        GetgroupsGoods({ category_id: el.category_id }).then(res => {
          if (res.error_code == 0) {
            el['selectedRowKeys'] = []
            that.flag = false
            that.$nextTick(() => {
              that.groupList[idx]['goodsList'] = res.data
              that.flag = true
            })
          }
        })
      })
      that.flag = true
      console.log(this.groupList, '/ this.groupList');

    },

    // 添加商品
    addShops(it) {
      this.consumable.mockData = []
      this.consumable.category_id = it.category_id
      this.consumable.targetKeys = []
      this.consumable.selectedRowKeys = []
      let that = this
      let obj = {
        activeStatus1: {
          id: "1",
          name: "机构简称"
        },
        value: ''
      }
      // this.loadProduct()
      this.loadProductAll()
      // this.getListSearchlimits(obj)
      this.consumable.visible = true
      // let that=this
      // that.groupList.forEach((el,idx)=>{
      //   if(el.group==it.group){
      //     that.groupList[idx].goodsList.push({})
      //   }
      // })

    },
    // 授权机构 搜索
    getListSearchlimits(e) {
      console.log(e);
      let str = ''
      // 挑选数据 打乱顺序 排到最上面
      if (e.activeStatus1.id == 1) {//商品名称
        str = 'goods_bn'
      }
      if (e.activeStatus1.id == 2) {//商品码
        str = 'goods_name'
      }
      let arr = []
      // consumable.mockData
      this.consumable.mockData.forEach((el, idx) => {
        if (el[str].includes(e.value)) {
          arr.push(el)
          this.consumable.mockData.splice(idx, 1);
        }
      })
      this.consumable.mockData = arr.concat(this.consumable.mockData)

    },
    async loadProductAll(goods_type) {
      let that = this
      that.consumable.mockData = []
      that.consumable.newdatasorce = []
      let data = {
        "filter": {
          // "goods_bn": '',
          // "goods_name": '',
          // "company_level": 1, //0总公司。1 省级代理 2连锁主体 3门店
          // "goods_type": goods_type
        },
        "limit": that.consumable.panigation.newTotol,
        "page": that.consumable.panigation.newCurrent
      }

      GetGoodslist(data).then(res => {
        let good_ids = []
        // let productList = res.data.list ? res.data.list : [];
        that.consumable.panigation.newTotol = res.data.total;
        // if (productList.length > 0) {
        res.data.list.forEach(el => {
          good_ids.push(el.goods_id)
          that.consumable.newdatasorce.push({
            key: el.goods_id.toString(),
            title: el.goods_name,
            id: el.goods_id,
            product_id: el.goods_id,
            goods_name: el.goods_name,
            goods_bn: el.goods_bn,
            product_bn: el.goods_bn,
            product_attr: [],
            goods_consume: '',
            goods_image: el.goods_image,
            disalbed: false,
            type: 'goods',
            goods_unit: el.goods_unit,
            goods_price: el.goods_price,
            goods_sales: el.goods_sales

          })
        })
        that.consumable.mockData = that.consumable.newdatasorce
        // that.getproductList(good_ids)
      })
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      // 
      let that = this
      return {
        getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
        // selectedRowKeys: that.consumable.selectedRowKeys,
        // onChange: (selectedRowKeys, selectedRows) => {
        //   
        //   that.consumable.selectedRowKeys = selectedRowKeys
        //   // type:'product'

        //   if (selectedRows.type == 'product') {
        //     that.consumable.selectedRowKeys.push(selectedRows.goods_id)
        //   }
        //   // that.consumable.selectedKeys.push(selectedRowKeys.splice(',')[0])
        //   that.consumable.selectedRows = selectedRows

        // }
      };
    },
    // 授权机构 搜索
    getListSearchlimits(e) {
      console.log(e);
      // 根据条件 判断是机构简称1 机构代码2 机构名称3 的搜索条件
      this.checkLimitsListfn()

    },
    checkLimitsListfn(e) {

    },
    //   确定添加商品 ok
    addconsumable() {
      console.log(this.consumable.targetKeys);
      if (this.consumable.targetKeys.length < 1) {
        return this.$message.error('请选择商品')
      }
      let obj = {
        category_id: this.consumable.category_id,
        goods_id: this.consumable.targetKeys
      }
      Savegoods(obj).then(res => {
        if (res.error_code == 0) {
          // 匹配商品列表
          this.matchGoods(this.consumable.category_id, this.groupList)
        }
      })
      this.consumable.visible = false
    },
    // 授权穿梭框相关
    onlimitsChange(nextTargetKeys) {
      this.consumable.targetKeys = nextTargetKeys;
      // this.consumable.selectedKeys = []

    },
    expandRowKeysFn(expanded, record) {
      if (expanded) {
        this.consumable.expandedRowKeys.push(record.id)
        // 只展开一行
        // if (this.consumable.expandedRowKeys.length > 0) { //进这个判断说明当前已经有展开的了
        //   //返回某个指定的字符串值在字符串中首次出现的位置，下标为0
        //   let index = this.consumable.expandedRowKeys.indexOf(record.id);
        //   if (index > -1) { //如果出现则截取这个id,1d到1相当于0，针对重复点击一个
        //     this.consumable.expandedRowKeys.splice(index, 1);
        //   } else {
        //     //如果没出现则截取所有id,添加点击id，0到1，针对已经有一个展开，点另一个会进入判断
        //     this.consumable.expandedRowKeys.splice(0, this.consumable.expandedRowKeys.length);
        //     this.consumable.expandedRowKeys.push(record.id);
        //   }
      } else {
        this.consumable.expandedRowKeys = this.consumable.expandedRowKeys.filter(item => item != record.id)
      }
    },
    rowClassName(record, index) {
      if (record.disalbed) {
        return "default-row double-row2";
      }
      return "default-row";
    },
    expandIcon(props) {
      if (props.record.children && props.record.children.length > 0) {
        if (props.expanded) {
          return (
            <span
              class="table-icon"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              收起
              <a-icon class='table-icon' type="up" />
            </span>
          );
        } else {
          return (
            <span
              class="table-icon  table-icon2"
              onClick={(e) => {
                props.onExpand(props.record, e);
              }}
            >
              展开
              <a-icon type="down" class='table-icon2' />
            </span>
          );
        }
      } else {
        return <span></span>;
      }

    },
    arrFn() {
      let arr = [
        {
          "product_bn": "jkbn2",
          "product_attr": ["颜色", "100ml"],
          "product_is_def": 1,
          "product_image": "https://sandbox.static.meirenji.vip/Laravel//2023/05/20/64682f3951a90.png",
          "product_price": "777.00", "product_id": "65",
          "id": "65-00-1-0", "goods_id": "15",
          "disalbed": true
        },
        {
          "key": "64", "title": "jkbn1", "goods_id": "15",
          "product_id": "64", "product_bn": "jkbn1",
          "product_attr": ["颜色", "250ml"],
          "product_is_def": 1,
          "product_image": "https://sandbox.static.meirenji.vip/Laravel//2023/05/20/64682f3951a90.png",
          "product_price": "777.00", "id": "64-1",
          "disalbed": true
        }]
    },
    getAutowidth() {
      let wid = document.body.clientWidth
      if (wid > 1680 && this.goods_specs_clomns.length > 6) {
        return 1500
      }
      return 1300
    },
    // 获取 产品
    getproductList(e) {
      let that = this;
      let data = {
        "filter": {
          "goods_id": e
        },
        "limit": 500,
        "page": 1
      }
      Allproduct(data).then(res => {
        let productList = res.data.list ? res.data.list : [];

        that.consumable.mockData.forEach((el, idx) => {
          // that.consumable.mockData[idx]['children'] = []
          productList.forEach((pro, index) => {
            if (pro.goods_id == el.id) {
              pro.key = el.id.toString() + '-' + pro.product_id
              // pro.key = pro.product_id + ''
              pro.title = el.goods_name
              pro.id = el.id.toString() + '-' + pro.product_id
              // pro.id = pro.product_id + ''
              pro.goods_name = el.goods_name
              pro.goods_bn = el.goods_bn
              pro.product_bn = pro.product_bn
              // pro.product_attr = that.changeobjtostr(pro.product_attr)
              pro.goods_consume = undefined
              pro.disalbed = true
              pro.goods_unit = el.goods_unit
              pro.product_id = pro.product_id
            }
          })
        })
        that.consumable.num++
        that.consumable.mockData = productList
        console.log(that.consumable.mockData, ">>>>>>>>>>Allproduct");

        console.log(this.consumable.targetKeys, 'consumable.targetKeys');
      })


    },
    // 修改分类分组
    updateGroups() {
      let obj = {
        category_id: '',
        category_name: '',
        category_title: '',
        category_background_image: ''
      }
      UpdategroupsGoods(obj).then(res => {

      })
    },

    //删除类
    deletetype(record) {
      let self = this
      let obj = {
        category_id: record.category_id
      }
      this.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title: '提示',
        content: '确认删除销售类目吗？',
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          Deteletypes(obj).then(res => {
            if (res.error_code == 0) {
              self.getTreeListFn()
            }
          })
        },
        onCancel() { },
      })
    },
    // 保存类
    savetype(record) {
      //  发送请求
      if (record.category_name == '' || !record.category_name) {
        return this.$message.error('类目名称不能为空')
      }
      let obj = {
        category_id: record.category_id,
        category_name: record.category_name,
      }
      let that = this
      UpdategroupsGoods(obj).then(res => {
        console.log(res);
        if (res.error_code == 0) {

          // that.editTable.tableData.forEach((el, idx) => {
          //   if (el.category_id == record.category_id) {
          //     // this.editTable.tableData[idx].isEidt = false
          //     el.isEidt = false
          //   }
          // })
          this.getTreeListFn()
        }
      })

    },
    // 编辑类
    editType(record) {
      this.editTable.tableData.forEach((el, idx) => {
        if (el.category_id == record.category_id) {
          el.isEidt = true
        }
      })
      console.log(this.editTable);
    },
    //取消编辑
    cancletype(record) {
      // this.editTable.tableData.forEach((el, idx) => {
      //   if (el.category_id == record.category_id) {
      //     // this.editTable.tableData[idx].isEidt = false
      //     el.isEidt = false
      //   }
      // })
      // // 刷新数据
      this.getTreeListFn()
    },
    //编辑组
    editGroups(record) {
      this.groupList.forEach((el, idx) => {
        if (el.category_id == record.category_id) {
          this.groupFlag = false
          el.isEdit = true
          this.groupFlag = true
        }
      })
      console.log(this.groupList);
    },
    // 删除组
    deleteGroups(record) {
      let self = this
      let obj = {
        category_id: record.category_id
      }
      this.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title: '提示',
        content: '确定删除分组吗？',
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          Deteletypes(obj).then(res => {
            if (res.error_code == 0) {
              self.getTreeListFn()
            }
          })
        },
        onCancel() {
        },
      })

    },
    // 保存zu
    saveGroups(record) {
      if (record.category_name == '' || !record.category_name) {
        return this.$message.error('分组名称不能为空')
      }
      let obj = {
        category_id: record.category_id,
        category_name: record.category_name,
        category_title: record.category_title,
        category_background_image: record.category_background_image
      }
      let that = this
      UpdategroupsGoods(obj).then(res => {
        console.log(res);
        if (res.error_code == 0) {

          this.getTreeListFn()
        }
      })
    },
    // 取消分组编辑
    cancleGroups(record) {
      this.getTreeListFn()
    },
    // 删除关联商品
    deleteGoods(record) {
      // 需要关联组id category_id
      // 新增确认弹框
      let self = this
      this.$confirm({
        icon: 'exclamation-circle',
        closable: true,
        title: '提示',
        content: '确认删除关联商品吗？',
        okText: '确定',
        okType: 'primary',
        cancelText: '取消',
        onOk() {
          DetelegroupsGoods({ category_goods_id: [record.category_goods_id] }).then(res => {
            if (res.error_code == 0) {
              self.getTreeListFn()
            }
          })
        },
        onCancel() { },
      })
    },
    //取消
    cancel() {
      let self = this
      self.$confirm({
        closable: true,
        title: '提示',
        content: '当前已填写数据尚未保存，确定取消保存吗？',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          self.$router.push({ path: '/shop/management/managementlist' })
          Msg.$emit('changethirdaryShow', true)
        },
        onCancel() { },
      })

    },
    // 开始获取首页轮播图
    getSwipper() {
      Getswippers().then(res => {
        if (res.error_code == 0) {
          this.form.goods_slider = res.data.carousel_data
          this.form.minites = res.data.stay_time == 5 ? 2 : 1
          let fileList = []
          // 回显轮播图
          this.form.goods_slider.forEach((val, key) => {
            fileList.push({
              uid: '-' + key,
              // name: '' + val.name, // 文件名
              status: 'done',
              url: '' + val
            })
          })

          if (this.$refs.uploadCom && this.$refs.uploadCom.fileList) {
            this.$refs.uploadCom.fileList = fileList
          }
        }
      })
    },

  },
  computed: {
    rowSelection() {
      let that = this
      return {
        // selectedRowKeys: that.limitsModel.selectedleftRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          // 搞事情  选项  selectedRows 
          console.log(selectedRowKeys, selectedRows);

          that.groupList.forEach((el, idx) => {
            that.groupList[idx].selectedRowKeys = []
            selectedRows.forEach((m, n) => {
              if (el.category_id == m.category_id) {
                that.flag = false
                that.groupList[idx].selectedRowKeys.push(m.category_id)
                that.groupList[idx].selectedRowKeys = [...new Set(that.groupList[idx].selectedRowKeys)]
                that.flag = true
              }
            })

          })
          that.getRowSelection({ disabled: false })
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
    // goods_name: {
    //   get() {
    //     return this.form.goods_name
    //   },
    //   set(val) {
    //     this.form.goods_name = val
    //     this.form.goods_bn = ''
    //     let that = this
    //     let a = pinyin(val, { toneType: 'none' })
    //     let b = a.split(' ')
    //     let arr = []
    //     b.forEach(el => {
    //       arr.push(el.slice(0, 1))
    //     })
    //     arr = arr.join('')
    //     this.form.goods_bn = arr
    //   },
    // },
  },
};
</script>
<style lang="less" scoped>
.add {
  display: flex;
  font-family: PingFangSC-Medium, PingFang SC;
  padding: 14px 18px 13px;
  flex: 1;
  position: relative;
  height: calc(100vh - 74px);
  overflow: hidden;
  overflow-y: auto;
  .addleft {
    margin-right: 18px;
    width: 56px;
    height: 300px;
    z-index: 10;
    position: fixed;
    .onetxt {
      width: 16px;
      height: 16px;
      background: @stepDbgColor;
      border-radius: 16px;
      text-align: center;
      line-height: 16px;
      margin-bottom: 3px;
      font-size: 12px;
      font-family: Helvetica;
      color: #ffffff;
      margin-left: 33px;
    }
    .active {
      background: @stepAbgColor;
    }
    .base {
      width: 56px;
      height: 37px;
      font-size: 14px;
      font-weight: 500;
      color: @stepDfontColor;
      line-height: 20px;
      margin-bottom: 4px;
      text-align: right;
    }
    .active1 {
      color: @stepAfontColor;
      font-size: 14px;
      font-weight: 600;
    }
    .line {
      width: 1.5px;
      height: 32px;
      background: @steplineColor;
      margin: 0 5px 6px 40px;
    }
  }
  .mgl-54 {
    margin-left: 54px;
  }
  .addright {
    margin-left: 66px;
    padding-bottom: 20px;
    flex: 1;
    background: #ffffff;
    border-radius: 4px;
    position: relative;
    overflow: auto;
    display: flex;
    padding: 54px 0 140px 0px;
    .phoneboard {
      width: 450px;
      height: 624px;
      background: #f1f4f4;
      border-radius: 4px;
      margin-right: 42px;
      position: relative;
      display: flex;
      justify-content: center;
      .tip {
        position: absolute;
        top: 20px;
        right: 18px;
        width: 48px;
        height: 23px;
        background: @primaryColor;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        line-height: 23px;
        cursor: pointer;
      }
      .picture_board {
        width: 240px;
        height: 576px;
        margin-top: 24px;
        // background: url(../../../assets/images/mainbg.jpg) no-repeat;
        // background-size: 100%;
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        .mainbg {
          width: 240px;
          height: auto;
          position: absolute;
          top: 0;
          left: 0;
        }
        .mainfootbg {
          width: 240px;
          height: auto;
          position: absolute;
          bottom: 0;
          left: 0;
        }
        .picture_board_swicher {
          width: 100%;
          height: 108px;
          background: transparent;
          margin-bottom: 100px;
          position: absolute;
          top: 0;
          left: 0;
        }
        .picture_board_tabstxt {
          width: 85%;
          margin-left: 56px;
          .default_tabs {
            height: 27px;
            margin-top: -3px;
            position: absolute;
            top: 289px;
          }
          /deep/ .default_tabs.ant-tabs .ant-tabs-tab::before {
            display: none;
          }
          /deep/ .default_tabs.ant-tabs .ant-tabs-bar {
            height: 24px !important;
            padding: 0 0 0 2px !important;
            line-height: 24px !important;
            background: transparent !important;
            border-bottom: none !important;
          }
          /deep/ .default_tabs.ant-tabs .ant-tabs-tab {
            text-shadow: 0 0 0.25px currentColor;
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            height: 25px !important;
            padding: 0 !important;
            line-height: 25px !important;
            margin: 0 0px 0 0;
            padding: 0;
            text-decoration: none;
            cursor: pointer;
            font-size: 12px !important;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: @fontColor4;
            transform: scale(0.73);
          }
          /deep/.ant-tabs-tab-active::before {
            display: none;
          }
          /deep/ .ant-tabs-left-no-ctn {
            /deep/ .ant-tabs-left-bar {
              border-right: 2px solid #bfd6d4;
              float: left;
              margin-right: -1px;
              margin-bottom: 0;
              height: 100%;
              border-bottom: 0;
            }
            /deep/ .ant-tabs-tab-active::before {
              display: none;
            }
            /deep/ .ant-tabs-tab::after {
              display: none;
            }
            // width: 74px;
            /deep/ .ant-tabs-tab {
              &::before {
                display: none;
              }
              &::after {
                display: none;
              }
            }
            .ant-tabs-bar {
              height: auto !important;
              padding-left: 0;
              padding-top: 0;
              background: transparent !important;
              border-bottom: none;
            }
          }
          /deep/.ant-tabs-nav-container-scrolling {
            padding-right: 25px;
            padding-left: 5px;
          }
          /deep/.ant-tabs-tab-prev-icon-target,
          /deep/ .ant-tabs-tab-next-icon-target {
            font-size: 12px;
            opacity: 0;
          }
          /deep/ .ant-tabs-ink-bar {
            margin-left: 20px;
            border-radius: 12px;
            width: 10px !important;
            bottom: 2px;
            height: 2.2px;
            // background-color: transparent !important;
          }
          /deep/ .ant-tabs-tab-next-icon {
            left: 26%;
          }
          /deep/ .ant-tabs-tab-active {
            color: @fontColor4 !important;
          }
        }
        .picture_board_goodscard {
          min-height: 474px;
          // margin-left: 9px;
          position: absolute;
          top: 318px;
          left: 0;
          width: 100%;
          position: relative;
          .goods_cards_box {
            height: 330px;
            width: 94%;
            margin-left: 9px;
            margin-bottom: 10px;
            background: #bfd6d4;
            position: relative;
            .goodsbg {
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 330px;
              border-radius: 8px;
            }
            .top_goodsname {
              position: absolute;
              left: 18px;
              top: 40px;
              height: 30px;
              font-size: 22px;
              font-weight: bold;
              color: #ffffff;
              line-height: 30px;
            }
            .top_goodsalname {
              position: absolute;
              left: 18px;
              top: 66px;
              height: 25px;
              font-size: 18px;
              font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
              font-weight: 400;
              color: #f5f9fe;
              line-height: 25px;
            }
            .goodscards {
              width: 100%;
              height: 205px;
              position: absolute;
              left: 10px;
              top: 114px;
              background: green;
              margin-left: -10px;
              display: flex;
              flex-wrap: nowrap;
              overflow: auto;
              .goodscards_items {
                width: 138px;
                height: 205px;
                background: #ffffff;
                border-radius: 8px;
                margin-right: 8px;
                text-align: center;
                .goodsMainimg {
                  width: 100%;
                  height: 127px;
                }
                .goodsmainname {
                  width: 103px;
                  height: 30px;
                  font-size: 12px;
                  font-weight: 400;
                  color: rgba(4, 4, 4, 0.6);
                  line-height: 15px;
                  margin-left: 18px;
                  text-align: left;
                  margin-bottom: 12px;
                }
                .goodsmainprice {
                  margin-left: 15px;
                  text-align: left;
                  .pri1 {
                    height: 15px;
                    font-size: 14px;
                    font-weight: bold;
                    color: #040404;
                    line-height: 15px;
                    margin-right: 3px;
                  }
                  .pri2 {
                    height: 15px;
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(139, 136, 134, 0.6);
                    line-height: 15px;
                    text-decoration: line-through;
                  }
                  .goodAdd {
                    display: inline-block;
                    margin-left: 5px;
                    width: 28px;
                    height: 24px;
                    line-height: 24px;
                    margin-left: 11px;
                    text-align: center;
                    background: #e8bb71;
                    border-radius: 12px;
                    .meiye-routeicon-add-copy {
                      font-size: 12px;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    /deep/.ant-col-4 {
      width: 136px !important;
    }
    .zengpinstep {
      padding: 31px 0 26px 28px;
      background: #fff;
      overflow: hidden;
    }
    .zengpinstep2 {
      padding-bottom: 40px;
    }
    .twoh1 {
      display: flex;
      margin-bottom: 20px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 2px;
        margin: 4px 8px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 19px;
      }
    }
    .twoh2 {
      display: flex;
      margin-bottom: 30px;
      .tpointer {
        display: inline-block;
        width: 3px;
        height: 10px;
        background: @primaryColor;
        border-radius: 2px;
        margin: 7px 8px 0 0;
      }
      .ttxt {
        height: 22px;
        font-size: 18px;
        font-weight: 600;
        color: @fontColor1;
        line-height: 25px;
      }
    }
    .twoh3 {
      display: flex;
      margin-bottom: 12px;
      .tpointer {
        display: inline-block;
        width: 6px;
        height: 6px;
        background: @primaryColor;
        border-radius: 2px;
        margin: 7px 8px 0 0;
      }
      .ttxt {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: @fontColor1;
        line-height: 20px;
      }
    }
    .one {
      height: calc(100vh - 156px);
    }
    .two {
      height: calc(100vh - 156px);
      overflow-y: auto;
      padding-bottom: 60px;
    }
    .three {
      height: calc(100vh - 156px);
    }
    .four {
      height: 100%;
    }
    .textColor1 {
      display: inline-block;
      width: 136px;
      height: 22px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @fontColor3;
      line-height: 22px;
      // margin-bottom: 14px;
      .xing {
        color: #f35e67;
        font-weight: 500;
      }
    }
    .textSmall {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: @primaryColor;
      line-height: 26px;
      height: 26px;
      background: @gray-border-background;
      border-radius: 4px;
      opacity: 0.65;
      padding-left: 8px;
      margin-top: 13px;
    }
    .a-center {
      display: flex;
      align-items: flex-start;
    }
    .three-btns {
      padding-left: 50px;
      padding-bottom: 20px;
      margin-left: 2px;
      // position: fixed;
      // bottom: 23px;
      background: #ffffff;
      // padding-top: 20px;
      width: 83%;
      z-index: 10;
      // border-top: 1px solid @dropdownBs5a;
      border-radius: 0 0 4px 4px;
    }
    .textSmalls {
      font-size: 15px;
      font-weight: 400;
      color: #819190;
    }
  }
  /deep/ .ant-table-footer {
    background: @gray-border-background;
  }
}
.row-body {
  .top {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    .text-title {
      margin-left: 10px;
      font-size: 16px;
      font-weight: 400;
      color: @btnColorfont1;
    }
    .yuan-blue {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: #ffffff;
      border-radius: 100%;
      background: @primaryColor;
      border: 1px solid @dropdownBs5a;
    }
    .dian {
      color: #e5e8e5;
      margin: 0 10px;
    }
    .yuan {
      width: 38px;
      height: 38px;
      text-align: center;
      line-height: 38px;
      color: @fontColor7;
      border-radius: 100%;
      background: #fdfdff;
      border: 1px solid @btnColorshodew;
    }
  }
}
.goods_editor {
  width: 600px;
  height: 259px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #cdd5d8;
  overflow: hidden;
  overflow-y: auto;
}
.font-22 {
  font-size: 22px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @fontColor1;
}
// 面包屑相关
.mrj-layout-tabs-custom {
  border-bottom: 1px solid @breadcrumbdownlineColor;
}
.leftarrow {
  color: @breadcrumbbackiconColor !important;
  border: 1px solid @breadcrumbbackborderColor !important;
  outline: none;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  text-align: center;
  box-sizing: border-box;
  line-height: 10px;
  margin-top: 1px;
  padding-top: 1.2px;
  &:hover {
    color: @breadcrumbbackiconHColor !important;
    border: 1px solid @breadcrumbbackborderHColor!important;
    outline: none;
  }
  .arrleft {
    margin-top: -1px;
    &:hover {
      color: @baseColor70 !important;
    }
  }
  .meiye-fanghui {
    font-size: 14px;
    margin-left: -1px;
  }
}
.leftarrow_hover {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-index span {
  color: @baseColor11;
  font-size: 14px;
}
.mrj-layout-tabs-custom-index:hover .anticon {
  color: @baseColor70 !important;
  border: 1px solid !important;
  outline: none;
}
.mrj-layout-tabs-custom-fenge {
  color: @breadcrumblineColor;
}
.mrj-layout-tabs-custom-inner-page {
  color: @fontColor4;
  font-size: 14px;
}
.samerow {
  width: 100%;
  /deep/.ant-form-item {
    display: flex;
    align-items: center;
    width: 100%;
  }
  /deep/ .ant-cascader-picker-label {
    display: inline-block;
  }
}
.samerow1 {
  /deep/ .ant-form-item-children {
    display: flex;
    align-items: center;
    width: 60%;
    justify-content: space-between;
  }
}

.samerow2 {
  /deep/.ant-form-item-label-left {
    margin-left: 68px;
  }
}
#styledatas {
  div {
    width: auto !important;
  }
}
// 单选另一种框
.checkqystatus {
  width: 180px;
  height: 32px;
  line-height: 32px;
  background: @labelDbgColor;
  // box-shadow: inset 0px 3px 0px 0px @labelDinsertColor;
  border-radius: 4px;
  border: 1px solid @labelDborderColor;
  box-sizing: border-box;
  padding: 3px;
  font-size: 16px;
  .cqt1 {
    width: 90px;
    height: 28px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    color: @labelDfontColor;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    line-height: 26px;
    &:hover {
      color: @labelHfontColor;
    }
  }
  .cqt1A {
    width: 90px;
    height: 28px;
    background: @labelAbgColor;
    box-shadow: inset 0px -2px 0px 0px @labelAinsertColor;
    // box-shadow: inset 0px -2px 0px 0px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    border: 1px solid @labelAborderColor;
    color: @labelAfontColor;
    text-align: center;
    line-height: 26px;
  }
}
//单规格
.sigleguige {
  .tabelHeader {
    // width: 1605px;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: rgba(241, 246, 245, 0.6);
    border-radius: 4px;
    position: relative;
    padding-left: 25px;
    display: flex;
    align-items: center;
    &::after {
      content: " ";
      width: 100%;
      height: 2px;
      background: #e8edec;
      position: absolute;
      top: -4px;
      left: 0;
    }
    .tabelHeader_items {
      width: 188px;
      text-align: left;
      display: inline-block;
    }
    .tabelHeader_items-278 {
      width: 278px;
    }
  }
  .tableBody {
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    border-bottom: 1px solid #e6eded;
    .tabelBody_items {
      width: 188px;
      text-align: left;
    }
    .tabelBody_items-278 {
      width: 278px;
    }
  }
}
.two {
  /deep/ .avatar-uploader,
  /deep/ .avatar-uploader .ant-upload {
    width: 48px !important;
    height: 48px !important;
    // padding-top: 4px;
  }
}

/deep/.two .avatar-uploader {
  width: 48px !important;
  height: 48px !important;
  padding-top: 9px;
  margin: 0 !important;
}
/deep/.two .ant-upload.ant-upload-select-picture-card {
  margin: 0 !important;
}
/deep/.uploaderguige .ant-upload-picture-card-wrapper .ant-upload {
  width: 48px !important;
  height: 48px !important;
  padding: 0;
  margin: 0;
}
.baseColor11 {
  color: @baseColor11 !important;
}
.htmlboxshops {
  &:focus-visible {
    border: none !important;
  }
}
.addGuigeRules {
  margin-top: 32px;
  font-size: 16px;
  font-weight: 400;
  color: #2d3835;
  i {
    color: #2d3835;
  }
}
.guigeline {
  width: 2px;
  height: 31px;
  background: #e8edec;
  margin: 0 0 0 48px;
}
.batchSet {
  height: 73px;
  background: #f1fafa;
  line-height: 73px;
  font-size: 14px;
  font-weight: 400;
  color: @fontColor4;
  .batchSet_h2 {
    font-size: 14px;
    font-weight: 500;
    color: @fontColor4;
    margin: 0 52px 0 10px;
  }
  .guigeImg {
    height: 73px;
    line-height: 73px;
    flex-wrap: nowrap;
    .guigeImg_txt {
      font-size: 14px;
      display: inline-block;
      font-weight: 400;
      color: @fontColor4;
      height: 73px;
      line-height: 73px;
    }
    /deep/ .ant-upload-picture-card-wrapper .ant-upload {
      margin: 0;
      width: 48px !important;
      height: 48px !important;
      border-radius: 4px;
      // border: 1px dashed #9ba7a7;
    }
    /deep/.ant-upload.ant-upload-select-picture-card:hover {
      border-color: @primaryColor;
    }
  }
}
.singlePrice {
}
.guigeTablebox {
  margin-top: 8px;
  /deep/ .ant-spin-nested-loading {
    min-height: 40px;
  }
}
.goods_specx_add {
  position: relative;
  .guigeline {
    position: absolute;
    top: 0;
    right: 0;
  }
}
/deep/ .avatar-uploader2.ant-upload-picture-card-wrapper .ant-upload {
  width: 66px !important;
  height: 66px !important;
  // border: 1px solid #d9d9d9;
  background: #fff;
}

.boldimgspan {
  position: relative;
  .meiye-close-boldimg {
    position: absolute;
    top: -21px;
    right: -8px;
    font-size: 12px;
  }
}
.addBox .add .mgb-32 {
  margin-bottom: 32px;
}

// 多规格样式修改
/deep/.guigeTablebox .ant-table .ant-table-body .datestyle {
  text-align: center;
  border-right: 1px solid @listbodylineColor !important;
}

/deep/.guigeTablebox .ant-table .pdl-24 {
  padding-left: 6% !important;
}
/deep/.guigeTablebox .ant-table .ant-table-thead .pdl-24 {
  padding-left: 6% !important;
}
/deep/.selectGg .ant-select-dropdown-menu {
  padding-bottom: 0 in !important;
}
/deep/.mgb-18.ant-form-item {
  margin-bottom: 18px;
}
.wid-132 {
  width: 132px;
}
/deep/.samerow .ant-cascader-picker-label {
  // display: none;
  box-shadow: none;
}
.pladd {
  color: rgba(45, 56, 53, 0.4);
}
.add .addright .last-btns-step2 {
  height: 68px;
  width: calc(100vw - 115px);
  position: fixed;
  bottom: 0;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 2;
  padding-left: 54px;
}
.mgl-28 {
  margin-left: 28px;
}
.chooseSku {
  display: block;
}
.mgr-20 {
  margin-right: 40px;
}
.guigeImg .mgr-20 {
  margin-right: 40px !important;
}
@media screen and(min-width:1680px) {
  .guigeImg .mgr-20 {
    margin-right: 68px !important;
  }
  .two .mgr-20 {
    margin-right: 68px;
  }
}
.toopt2 {
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  width: 100%;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @baseColor18;
  line-height: 17px;
  margin-bottom: 12px;
  margin-top: 16px;
  text-align: left;
  // margin-top: 12px;
}
.threepopimg {
  width: 165px;
  height: 130px;
  margin-bottom: 10px;
}
.toopt3 {
  text-align: left;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @baseColor18;
  line-height: 17px;
  margin-bottom: 30px;
}
.toopt4 {
  width: 36px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  // color: #c3c9c4;
  color: @baseColor18;
  line-height: 17px;
  text-align: center;
  position: absolute;
  left: 116px;
  top: 107px;
}
.toopt5 {
  width: 36px;
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: @baseColor18;
  line-height: 17px;
  position: absolute;
  right: 13px;
  top: 162px;
}

/deep/ .ant-popover-inner-content {
  text-align: center;
}
.pop1 {
  width: 171px;
  // height: 304px;
  margin-left: 33px;
}
.pop2 {
  width: 62px;
  position: absolute;
  left: 48px;
  top: 113px;
}
.pop3 {
  width: 62px;
  position: absolute;
  left: 157px;
  top: 113px;
}
.pop4 {
  width: 2px;
  height: 48px;
  position: absolute;
  right: 36px;
  top: 207px;
}
.pop5 {
  width: 6px;
  /* height: 54px; */
  position: absolute;
  right: 34px;
  top: 133px;
}
.tip_sp {
  display: inline-block;
  width: 48px;
  height: 23px;
  text-align: center;
  line-height: 23px;
}
.swiper {
  height: 108px;
  width: 94%;
  border: 1px transparent solid;
  overflow: hidden;
  margin-top: 80px;
  margin-left: 8px;
  /deep/ .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    height: 108px;
    font-size: 14px;
    // background-color: #62a8c8;
    // margin-top: 57px;
  }
}
.sliderimg {
  width: 223px;
  height: 108px;
}
.h2leftlable {
  display: inline-block;
  width: 110px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: @fontColor3;
}
.mgb-40 {
  margin-bottom: 40px;
}
.swiper-box {
  text-align: left;

  /deep/.ant-spin-nested-loading {
    min-height: 83px;
  }
  /deep/ .ant-spin-container {
    justify-content: flex-start;
  }
}
.addBox .add .ant_card_tabs {
  // 基本信息  基础配置
  width: 100%;
  background: #fff;
  margin-bottom: 22px;
  & /deep/ .ant-tabs-bar {
    height: 49px !important;
    line-height: 49px !important;
    padding: 20px 0 0 0px;
    background: #fff !important;
    margin: 0px;
    display: flex;
    flex-direction: row-reverse;
  }

  & /deep/ .ant-tabs-tab {
    height: 29px !important;
    line-height: 29px !important;
    background: #fff !important;
    // border: 1px solid #d3dedc !important;
    border: none !important;
    // border-color: #d3dedc !important;
    background: transparent !important;
    font-size: 15px !important;
    // margin-right: 12px;
    padding: 0 18px;
  }

  & /deep/ .ant-tabs-tab-prev.ant-tabs-tab-arrow-show,
  & /deep/ .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    margin: 0 !important;
    height: 29px !important;
    font-weight: 600 !important;
    line-height: 29px !important;
    border: none !important;
    // border-color: #e8e8e8;
    // border-bottom: 1px solid #fff;
    font-size: 15px !important;
    color: #5b5a62;
    background: transparent !important;
    // padding: 20px 0 0 30px;
    // background: #fff !important;
  }

  & /deep/ .ant-tabs-tab {
    &::before {
      display: block;
      position: absolute;
      top: -2px;
      left: 0px;
      right: 0;
      bottom: -1.5px;
      background: url(../../../assets/images/tabs_card_1.png) no-repeat 0 0;
      background-size: 100% 100%;
      opacity: 1;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: transparent;
      background-size: 100% 100%;
      opacity: 1;
    }
  }
  & /deep/ .ant-tabs-tab:nth-child(2) {
    &::before {
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      right: 0;
      bottom: -1px;
      background: url(../../../assets/images/tabs_card_2.png) no-repeat 0 0;
      background-size: 100% 100%;
      background-size: cover;
      opacity: 1;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: rgb(244, 245, 247);
      background-size: 100% 100%;
      opacity: 1;
    }
  }
  & /deep/ .ant-tabs-tab-active {
    color: @baseColor11 !important;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #fff !important;
    // &::before {
    //   content: "";
    //   display: none;
    //   position: absolute;
    //   z-index: -1;
    //   top: -2px;
    //   left: 0;
    //   right: 0;
    //   bottom: -0.5px;
    //   background: url(~@/assets/images/tabs-unselected-1.png) no-repeat 0 0;
    //   background-size: 100% 100%;
    //   opacity: 1;
    // }
    &::after {
      content: "";
      display: block;
      position: absolute;
      z-index: -2;
      left: 0;
      width: 100%;
      height: 1px;
      bottom: 0px;
      background: transparent !important;
      background-size: 100% 100%;
      opacity: 1;
    }
  }
  & /deep/ .ant-tabs-extra-content {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    margin-left: 6px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: @fontColor3;
    cursor: pointer;
  }

  .basic_name {
    color: @fontColor4;
    p {
      margin-bottom: 0;
      padding: 0;
    }
    .ba_p2 {
      color: @fontColor5;
      margin-top: 3px;
    }
  }

  .basicSet {
  }
}
.redColor {
  color: @infoColor;
  font-size: 14px;
  margin-left: -3px;
}
.addtracking {
  height: 40px;
  background: #f0f6f5;
  border-radius: 4px;
  line-height: 40px;
  .addplus {
    display: inline-block;
    width: 24px;
    text-align: center;
    line-height: 24px;
    height: 24px;
    background: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(173, 207, 203, 0.35),
      inset 0px -3px 0px 0px #dbeae8;
    border-radius: 5px;
    border: 1px solid #bacbc7;
    margin: 0 8px 0 16px;
    cursor: pointer;
  }
}
.mgb-30 {
  margin-bottom: 30px;
}
.mgt-40 {
  margin-top: 40px;
}
.gooslable1 {
  font-size: 14px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: @fontColor4;
  margin-right: 8px;
}
.gooslable2 {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: @fontColor3;
  margin-right: 16px;
}
.ant-btn-grounding {
  height: 27px;
  line-height: 27px;
  margin-left: -4px;
}
.transferBox {
  /deep/ .ant-transfer-list-header {
    display: none;
  }
  position: relative;

  /deep/ .owninputbox .owninputbox_search .meiye-xiala {
    top: 0;
  }
  .transferleftips,
  .transferighgtips {
    position: absolute;
    width: 545px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: @fontColor4;
    line-height: 22px;
    top: 8px;
    text-align: left;
    z-index: 1;
  }

  .topsline {
    width: 3px;
    height: 10px;
    background: @primaryColor;
    border-radius: 2px;
    display: inline-block;
  }
  .nomaltxt {
    font-size: 16px;
    font-weight: 500;
    color: #9ea6a4;
  }
  .transferleftips {
    left: 0px;
    top: 1px;
  }
  .transferighgtips {
    right: 0;
  }
}
.box_tit {
  height: 64px;
  line-height: 64px;
  .boxtit_left {
    width: 42px;
    height: 42px;
    margin-right: 12px;
    margin-top: 11px;
  }
  .boxtit_rgt {
    p {
      margin: 0;
      bottom: 0;
    }
    .boxtit_rgt_top {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: @fontColor4;
      height: 20px;
      line-height: 20px;
      margin-top: 14px;
    }
    .boxtit_rgt_bot {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: @fontColor5;
      height: 17px;
      line-height: 17px;
    }
  }
}
.edittype {
  display: inline-block;
  margin-right: 24px;
}
.last-btns-step2 {
  height: 68px;
  width: calc(100vw - 278px);
  position: fixed;
  bottom: 21px;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid #d8eceb;
  z-index: 100;
}
</style>